.as-form-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.full-width {
  width: 100%;
}

.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.icon-button {
  border: none;
  background-color: transparent;
}

.collapsible {
  position: relative;
}

.collapse-button {
  position: absolute;
  right: 0;
  top: 15px;
}

.suggestions {
  background-color: var(--kt-input-solid-bg);
  border-radius: 8px;
  max-height: 200px;
  overflow: scroll;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.2);
  box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.2);
}

.suggestion-item {
  cursor: pointer;
  margin: 8px;
  padding: 4px;
  border-bottom: 0.5px solid #909090;
}

.editor-link {
  // text-decoration: underline;
  color: #30ab4c;
  cursor: pointer;
}

.editor-button {
  background-color: yellow;
  border-radius: 5px;
  border: 2px solid black;
  color: black;
  font-size: 1rem;
  padding: 5px 10px;
  font-weight: bold;
}

.purchase-wrapper {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f5f8fa;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #EAEAEA;
  }
}

.source-modal-root {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.source-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.4);
  z-index: 1;
}

.source-modal-body {
  z-index: 2;
  background-color: white;
  border-radius: 6px;
  padding: 50px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;

  input {
    min-width: 300px;
    background-color: transparent;
    border: none;
    border-bottom: 0.5px solid gray;
    padding: 4px;
    font-size: 1rem;
    margin-top: 10px;
  }

  button {
    border: none;
    border-radius: 4px;
    padding: 8px;
    font-size: 1rem;
    background-color: lightgray;
    margin-top: 10px;

    &.primary {
      background-color: #009ef7;
      color: white;
    }

    &.submit {
      background-color: green;
      color: white;
    }
  }
}

.option-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  div {
    display: flex;
    flex-direction: column;
  }
}

/* Tooltip container */
.tooltipCustom {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltipCustom .tooltipCustomText {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: normal;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  // width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -50%; /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipCustom:hover .tooltipCustomText {
  visibility: visible;
}

.dates-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: "100%";
  flex-wrap: wrap;
}

.date-block {
  background-color:var(--kt-input-solid-bg);
  border-radius: 0.625rem;
  font-size: 1.15rem;
  padding: 0.825rem 1.5rem;
  color: var(--kt-input-solid-color);
}

.contact-form-field {
  border-left: 0.5px solid #cacaca;
  border-bottom: 0.5px solid #cacaca;
  border-top: 0.5px solid #cacaca;
  border-right: 0.5px solid #cacaca;
  // border-radius: 8px;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  padding: 8px 12px;
  white-space: pre-wrap;
}

.text-wrap-nowrap {
  white-space: nowrap;
  text-wrap: nowrap;
}

.reorderable-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reorderable-list-item {
  // background-color: red;
  cursor: grab;
  position: relative;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  // padding: 10px;
  // border-bottom: 1px solid #cacaca;

  &.disabled {
    cursor: default;
  }

  &:active {
    cursor: grabbing;
  }

  &.dragged {
    opacity: 0.5;
  }

  &.dragged-over {

    &.move-up {
      padding-top: 80px;

      &::before {
        content: "";
        position: absolute;
        height: 70px;
        top: 0;
        left: 0;
        right: 0;
        border: 2px dashed #000;
        border-radius: 8px;
      }
    }
    
    &.move-down {
      padding-bottom: 80px;

      &::after{
        content: "";
        position: absolute;
        height: 70px;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px dashed #000;
        border-radius: 8px;
      }
    }
  }
}

.table--header-cell {
  font-size: 13px;
}

.table--body-cell {
  font-size: 13px;;
}

.ml-auto {
  margin-left: auto !important;
}
